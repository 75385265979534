import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Under construction</h1>
        <br />
        <p>Tech with Erick, Inc.</p>
        <p>contact@techwitherick.com</p>
        <p>Phone: +1 7602848473</p>
      </header>
    </div>
  );
}

export default App;
